
































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import {
  EditPeerGroupItemPayload,
  ProjectValuationReportPeerGroupItem,
} from "@/store/projectValuationReport/types";
import PeerGroupItemEdit from "@/components/project-valuation-report/PeerGroupItemEdit.vue";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import Industry from "@/entity/Industry";
import Contact from "@/entity/Contact";
import { ReferenceItem } from "@/services/types";

const module = namespace("projectValuationReport");

@Component({
  components: {
    PeerGroupItemEdit,
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage9Edit extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;
  @Prop({ type: Array, required: true }) industryOptions!: Industry[];
  @Prop({ type: Array, required: true }) contactOptions!: Contact[];
  @Prop({ type: Array, required: true })
  incomeStatementOptions!: ReferenceItem[];

  @module.Getter("getPeerGroup")
  peerGroup!: ProjectValuationReportPeerGroupItem[];
  @module.Mutation("editPeerGroupItem")
  editPeerGroupItem!: (payload: EditPeerGroupItemPayload) => void;

  editItem(index: number, item: ProjectValuationReportPeerGroupItem) {
    this.editPeerGroupItem({
      index,
      item,
    });
  }

  get years(): number[] {
    const startYear = 2010;
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i <= currentYear + 1; i++) {
      years.push(i);
    }
    return years;
  }

  get yearFrom(): number {
    return this.peerGroup[0].yearFrom;
  }

  set yearFrom(value: number) {
    // do nothing
  }

  get yearTo(): number {
    return this.peerGroup[0].yearTo;
  }

  set yearTo(value: number) {
    // do nothing
  }
  get branchIds(): number[] | null {
    return this.peerGroup[0].branchIds;
  }

  set branchIds(value: number[] | null) {
    // do nothing
  }

  get contactId(): number[] | null {
    return this.peerGroup[0].contactIds;
  }

  set contactId(value: number[] | null) {
    // do nothing
  }

  onYearFromInput(yearFrom: string | number) {
    for (let i = 0; i < 4; i++) {
      this.editPeerGroupItem({
        index: i,
        item: {
          ...this.peerGroup[i],
          yearFrom: Number(yearFrom),
        },
      });
    }
  }

  onYearToInput(yearTo: string | number) {
    for (let i = 0; i < 4; i++) {
      this.editPeerGroupItem({
        index: i,
        item: {
          ...this.peerGroup[i],
          yearTo: Number(yearTo),
        },
      });
    }
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }

  onBranchSelect(data: number[]) {
    this.reset("branchField");

    // Find all projects that are belong to the selected branch
    // and add those projects to selection
    const newIndustry = data[data.length - 1]; // New is always the last one
    const contactIds = this.contactOptions
      .filter((contact) => {
        const index =
          contact.contactBranches?.findIndex(
            (branch) => branch.id === newIndustry
          ) ?? -1;
        return index > -1;
      })
      .map((project) => project.id as number);

    const newContactIds: number[] = this.contactId || [];

    contactIds.forEach((contactId) => {
      if (newContactIds.indexOf(contactId) === -1) {
        newContactIds.push(contactId);
      }
    });

    for (let i = 0; i < 4; i++) {
      this.editPeerGroupItem({
        index: i,
        item: {
          ...this.peerGroup[i],
          branchIds: data,
          contactIds: newContactIds,
        },
      });
    }
  }

  onContactSelect(data: number[]) {
    this.reset("subBranchField");
    for (let i = 0; i < 4; i++) {
      this.editPeerGroupItem({
        index: i,
        item: {
          ...this.peerGroup[i],
          contactIds: data,
        },
      });
    }
  }

  handleReset() {
    const currentBranchId = this.industryOptions.find(
      (item) => item.name === this.snapshot.projectContactBranch
    )?.id;
    for (let i = 0; i < 4; i++) {
      this.editPeerGroupItem({
        index: i,
        item: {
          ...this.peerGroup[i],
          branchIds: currentBranchId ? [currentBranchId] : [],
          contactIds: [],
        },
      });
    }
  }

  created() {
    if (!this.branchIds?.length || !this.branchIds) {
      const currentBranchId = this.industryOptions.find(
        (item) => item.name === this.snapshot.projectContactBranch
      )?.id;
      if (currentBranchId) {
        for (let i = 0; i < 4; i++) {
          this.editPeerGroupItem({
            index: i,
            item: {
              ...this.peerGroup[i],
              branchIds: [currentBranchId],
            },
          });
        }
        this.onBranchSelect([currentBranchId]);
      }
    }
  }
}
