








































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { ProjectValuationReportPeerGroupItem } from "@/store/projectValuationReport/types";
import { inputToNumber } from "@/utils/number";
import RulesMixin from "@/mixins/RulesMixin";
import { ReferenceItem } from "@/services/types";
import AnalyticsService from "@/services/AnalyticsService";
import { AnalyticsQueryShort } from "@/entity/Analytics";
import { processAnalyticsResponse } from "../analytics/utils/processAnalyticsResponse";
import Industry from "@/entity/Industry";
import Contact from "@/entity/Contact";
import { getRowAverage } from "../analytics/utils";

@Component
export default class PeerGroupItemEdit extends Mixins(RulesMixin) {
  @Prop({ type: Object, required: true })
  item!: ProjectValuationReportPeerGroupItem;
  @Prop({ type: Array, required: true }) keyFigures!: ReferenceItem[];
  @Prop({ type: Array, required: true }) industryOptions!: Industry[];
  @Prop({ type: Array, required: true }) contactOptions!: Contact[];
  @Prop({ type: Object, required: true }) snapshot!: any;
  @Prop({ type: Number, required: true }) yearFrom!: number;
  @Prop({ type: Number, required: true }) yearTo!: number;
  @Prop({ type: Array, required: true }) branchIds!: number[];
  @Prop({ type: Array, required: true }) contactId!: number[];
  @Prop({ type: Function, required: true }) handleParentReset!: () => void;

  formData: AnalyticsQueryShort = {
    balanceIndicators: [],
    incomeStatementIndicators: [],
    derivedIndicators: [],
    yearFrom: null,
    yearTo: new Date().getFullYear() - 1,
  };

  onNameInput(name: string): void {
    this.$emit("input", {
      ...this.item,
      name,
    });
  }

  onValueInput(value: string): void {
    this.$emit("input", {
      ...this.item,
      value: inputToNumber(value),
    });
  }

  onOverallValueInput(overallValue: string): void {
    this.$emit("input", {
      ...this.item,
      overallValue: inputToNumber(overallValue),
    });
  }

  onComparisonKeyInput(value: string): void {
    this.$emit("input", {
      ...this.item,
      comparisonKey: inputToNumber(value),
    });
  }

  onRatioKeyInput(overallValue: string): void {
    this.$emit("input", {
      ...this.item,
      ratioKey: inputToNumber(overallValue),
    });
  }

  get comparisonKeys() {
    return this.keyFigures.map((item) => item.name);
  }

  get ratioKeys() {
    return this.keyFigures
      .map((item) => item.name)
      .filter((item) => item !== this.item.comparisonKey);
  }

  async handleCalculate() {
    if (!this.item.comparisonKey || !this.item.ratioKey)
      return alert("Please Choose all key figures");

    const comparisionKeyId = this.keyFigures.find(
      (item) => item.name === this.item.comparisonKey
    )?.id;
    const ratioKeyId = this.keyFigures.find(
      (item) => item.name === this.item.ratioKey
    )?.id;

    if (!comparisionKeyId || !ratioKeyId) return alert("Something went wrong");

    this.formData.incomeStatementIndicators = [comparisionKeyId, ratioKeyId];
    this.formData.yearFrom = this.yearFrom;
    this.formData.yearTo = this.yearTo;

    const snapshotContactId = this.snapshotContactId;
    if (!snapshotContactId) return;

    const [projectRawResponse, peergroupRawResponse] = await Promise.all([
      AnalyticsService.search({
        ...this.formData,
        branchIds: [],
        contactId: [snapshotContactId],
      }),
      AnalyticsService.search({
        ...this.formData,
        branchIds: this.branchIds,
        contactId: this.contactId,
      }),
    ]);
    const comparisonKeyFigures = {
      incomeStatement: this.item.comparisonKey,
      balanceSheet: "",
      derivedIndicators: "",
    };

    const projectProcessedData = processAnalyticsResponse(
      projectRawResponse,
      comparisonKeyFigures
    );

    const peergroupProcessedData = processAnalyticsResponse(
      peergroupRawResponse,
      comparisonKeyFigures
    );

    const peerGroupRatioIndicator = peergroupProcessedData.incomeStatement.find(
      (item) => item.variable === this.item.ratioKey
    );
    const projectAverageRatioIndicator =
      projectProcessedData.incomeStatement.find(
        (item) => item.variable === this.item.ratioKey
      );

    const peerAverage = getRowAverage(
      peerGroupRatioIndicator?.averageData.map((item) => ({
        value: item.ratio || 0,
      })) || []
    );

    const projectAverage = getRowAverage(
      projectAverageRatioIndicator?.averageData.map((item) => ({
        value: item.ratio || 0,
      })) || []
    );

    if (!peerAverage)
      return alert(
        `There's no Average for Peergroup in Range ${this.yearFrom} - ${this.yearTo}`
      );
    if (!projectAverage)
      return alert(
        `There's no Average for Project in Range ${this.yearFrom} - ${this.yearTo}`
      );

    const data = {
      ...this.item,
      overallValue: inputToNumber(peerAverage.toFixed()),
      value: inputToNumber(projectAverage.toFixed()),
    };

    this.$emit("input", data);
  }

  get snapshotContactId() {
    const snapshotContact = this.contactOptions.find(
      (item) => item.name === this.snapshot.companyName
    );
    if (!snapshotContact)
      return alert("Can't find PROJECT ID. Something Went Wrong");
    return snapshotContact.id;
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }
}
