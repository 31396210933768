




























































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import ValueOrNA from "@/components/common/ValueOrNA.vue";
import ScoreTableTotalRow from "@/components/project-valuation-score/ProjectValuationScoreTableTotalRow.vue";
import InlineEditDialog from "@/components/common/InlineEditDialog.vue";
import { StructureScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    Rating,
    ValueOrNA,
    ScoreTableTotalRow,
    InlineEditDialog,
  },
})
export default class StructureScoreTable extends Vue {
  @Prop({ type: Object }) structureScore!: StructureScore;
  @Prop({ type: Boolean, default: false }) isSaving!: boolean;
  @Prop({ type: Boolean, default: false }) hasEditRights!: boolean;
  @Prop({ type: Function, default: false }) onSave!: () => void;

  @PropSync("locationBound", { type: String }) locationBoundLocal!:
    | string
    | null;
  @PropSync("estate", { type: String }) estateLocal!: string | null;
  @PropSync("revisedFinancialStatements", { type: String })
  revisedFinancialStatementsLocal!: string | null;
  @PropSync("evaluability", { type: String }) evaluabilityLocal!: string | null;

  get selectOptions() {
    return [
      {
        text: this.$t("yes"),
        value: "yes",
      },
      {
        text: this.$t("no"),
        value: "no",
      },
    ];
  }

  get extendedSelectOptions() {
    return [
      ...this.selectOptions,
      {
        text: this.$t("partly"),
        value: "partly",
      },
    ];
  }

  translateScore(value: string | null): string | null {
    if (value === null) return null;

    return this.$te(value) ? this.$t(value).toString() : null;
  }

  get scores() {
    return [
      {
        key: "locationBound",
        name: this.$t("valuation.score.structure.locationBound"),
        score: this.structureScore.locationBound.value,
        overallScore: this.translateScore(
          this.structureScore.locationBound.overallValue
        ),
        rating: this.structureScore.locationBound.rating,
        isPercentage: true,
      },
      {
        key: "estate",
        name: this.$t("valuation.score.structure.estate"),
        score: this.structureScore.estate.value,
        overallScore: this.translateScore(
          this.structureScore.estate.overallValue
        ),
        rating: this.structureScore.estate.rating,
        isPercentage: false,
      },
      {
        key: "revisedFinancialStatements",
        name: this.$t("valuation.score.structure.revisedFinancialStatements"),
        score: this.structureScore.revisedFinancialStatements.value,
        overallScore: this.translateScore(
          this.structureScore.revisedFinancialStatements.overallValue
        ),
        rating: this.structureScore.revisedFinancialStatements.rating,
        isPercentage: true,
      },
      {
        key: "evaluability",
        name: this.$t("valuation.score.structure.evaluability"),
        score: this.structureScore.evaluability.value,
        overallScore: this.translateScore(
          this.structureScore.evaluability.overallValue
        ),
        rating: this.structureScore.evaluability.rating,
        isPercentage: true,
      },
    ];
  }
}
