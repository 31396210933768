




















































































































import { Component, Prop, PropSync, Mixins } from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import ValueOrNA from "@/components/common/ValueOrNA.vue";
import RulesMixin from "@/mixins/RulesMixin";
import ScoreTableTotalRow from "@/components/project-valuation-score/ProjectValuationScoreTableTotalRow.vue";
import InlineEditDialog from "@/components/common/InlineEditDialog.vue";
import { RiskScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    Rating,
    ValueOrNA,
    ScoreTableTotalRow,
    InlineEditDialog,
  },
})
export default class RiskScoreTable extends Mixins(RulesMixin) {
  @Prop({ type: Object }) riskScore!: RiskScore;
  @Prop({ type: Boolean, default: false }) isSaving!: boolean;
  @Prop({ type: Boolean, default: false }) hasEditRights!: boolean;
  @Prop({ type: Function, default: false }) onSave!: () => void;

  @PropSync("ownerDependence", { type: [Number, String] })
  ownerDependenceLocal!: number | string | null;
  @PropSync("organisationSize", { type: [Number, String] })
  organisationSizeLocal!: number | string | null;
  @PropSync("staffTurnover", { type: [Number, String] }) staffTurnoverLocal!:
    | number
    | string
    | null;
  @PropSync("customerClusterRisk", { type: [Number, String] })
  customerClusterRiskLocal!: number | string | null;

  displayPercentageValue(value: any): string {
    return `${value} %`;
  }

  get scores() {
    return [
      {
        key: "ownerDependence",
        name: this.$t("valuation.score.risk.ownerDependence"),
        score: this.riskScore.ownerDependence.value?.toFixed(0),
        overallValue: this.riskScore.ownerDependence.overallValue?.toFixed(0),
        rating: this.riskScore.ownerDependence.rating,
        isPercentage: true,
        hoverText: this.$t("valuation.score.risk.ownerDependence.hover"),
      },
      {
        key: "organisationSize",
        name: this.$t("valuation.score.risk.organisationSize"),
        score: this.riskScore.organisationSize.value?.toFixed(0),
        overallValue: this.riskScore.organisationSize.overallValue?.toFixed(0),
        rating: this.riskScore.organisationSize.rating,
        isPercentage: false,
        hoverText: this.$t("valuation.score.risk.organisationSize.hover"),
      },
      {
        key: "staffTurnover",
        name: this.$t("valuation.score.risk.staffTurnover"),
        score: this.riskScore.staffTurnover.value?.toFixed(0),
        overallValue: this.riskScore.staffTurnover.overallValue?.toFixed(0),
        rating: this.riskScore.staffTurnover.rating,
        isPercentage: true,
        hoverText: this.$t("valuation.score.risk.staffTurnover.hover"),
      },
      {
        key: "customerClusterRisk",
        name: this.$t("valuation.score.risk.customerClusterRisk"),
        score: this.riskScore.customerClusterRisk.value?.toFixed(0),
        overallValue:
          this.riskScore.customerClusterRisk.overallValue?.toFixed(0),
        rating: this.riskScore.customerClusterRisk.rating,
        isPercentage: true,
        hoverText: this.$t("valuation.score.risk.customerClusterRisk.hover"),
      },
    ];
  }
}
