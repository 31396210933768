


























































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { BUYER_TYPE_DB_NAMES } from "@/constants";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage13Edit extends Vue {
  @module.Getter("getEvaluationOfBuyerDatabase")
  evaluationOfBuyerDatabase!: string;
  @module.Getter("getPrivateBuyer")
  privateBuyer!: number;
  @module.Getter("getStrategicBuyer")
  strategicBuyer!: number;
  @module.Getter("getFinancialInvestor")
  financialInvestor!: number;
  @module.Mutation("setEvaluationOfBuyerDatabase")
  setEvaluationOfBuyerDatabase!: (value: string) => void;
  @module.Mutation("setBuyerType")
  setBuyerType!: (value: {
    name: BUYER_TYPE_DB_NAMES;
    newCount: number;
  }) => void;

  @Prop({ type: Array, required: true })
  buyerTypesFromSnapshot!: ProjectValuationBuyerChart[];

  handlePrivateBuyerChange(e: number) {
    this.setBuyerType({
      name: BUYER_TYPE_DB_NAMES.privateBuyer,
      newCount: Number(e),
    });
  }

  handleStrategicBuyerChange(e: number) {
    this.setBuyerType({
      name: BUYER_TYPE_DB_NAMES.strategicBuyer,
      newCount: Number(e),
    });
  }

  handleFinancialInvestorChange(e: number) {
    this.setBuyerType({
      name: BUYER_TYPE_DB_NAMES.financialInvestor,
      newCount: Number(e),
    });
  }
}
